
.contact {
  background-color: var(--secondary-color);
  color: var(--font-color);
  margin: 0;
  position: relative;
  padding: 100px 20px;
}

.contact::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background-color: var(--primary-color);
  clip-path: polygon(50% 30%, 0 0, 100% 0);
}

.contact__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact__error-message {
    color: var(--tertiary-color);
}

.contact__title {
    text-align: center;
}

.contact__input-group {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    width: 100%;
}

.contact__buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.contact__form {
    width: 50%
}

.form-feedback {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error__message {
  color: red
}


/* small devices */

@media all and (max-width: 992px) {
  .contact__container {
    display: flex;
    flex-direction: column;
  }
  
  .contact__form-container {
    width: 100%;
  }
  
  .contact__information {
    width: 100%;
  }
  
  .contact__form {
    width: 100%
  }
}