.projects__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects__title {
    text-align: center;
}

.project__card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px
}

.tags {
  background-color: var(--secondary-color);
  margin-right: 10px
}

.card__title h3 {
  margin: 0;
}

.project__info {
  font-size: 12px;
}

.project__card img {
  width: 300px;
  height: 200px;
  margin: 20px 20px 20px 0px
}

.project__description {
  flex-grow: 1;
}

.project__card:hover {
    transform: scale(1.02)
}

.card__button {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.projects__links {
  font-size: 14px;
}

/* small devices */

@media all and (max-width: 992px) {
  .project__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .project__card img {
    width: 100%;
    height: 100%;
  }
}
