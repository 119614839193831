.theme-switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px
}

.theme-switch-wrapper p {
  margin-right: 10px;
  font-size: 1rem;
}

.theme-switch {
  display: inline-block;
  height: 25px;
  position: relative;
  width: 50px;
}

.theme-switch input {
  display:none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 18px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 18px;
}

input:checked + .slider {
  background-color: var(--tertiary-color);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}