.banner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner__container {
  display: flex;
}

.banner__portrait {
  max-width: 300px;
  max-height: 300px;
  border-radius: 50%;
  margin: 10px
}

.banner__content {
  display: flex;
  justify-content: start;
  flex-direction: column;
}

.banner__title {
  width: 250px;
}

.banner__title h2 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  border-right: 1px solid;
  animation: blink-title 1s step-end infinite, typing 4s steps(15, end);
}

/* small devices */

@media all and (max-width: 992px) {
  .banner__portrait {
    display: none;
  }
}

/* title animations */

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-title {
  0%,
  100% { border-color: transparent; }
  50% { border-color: var(--font-color); }
}
